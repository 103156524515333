import Vue from "vue";

export default {
    state: {
        currentOrder: false,
        selectedCartItemIndex: false,
        refundOrder: false,
        unsentOrderLines: [],
        unsentGiftCardChargeRequests: [],
        percentageDiscount: false,
        terminalText: null,
        QRUrl: null,
        autoNavigateUrl: null,
        table_uuid: null,
        table_uuids: [],
        clients_number: null,
        awaitingCancelAction: false,
        hidePayOutToCustomerNotification: false,
    },

    getters: {
        savedOrderLines: (state) => {
            return state.currentOrder !== false && 'order_lines' in state.currentOrder
                ? state.currentOrder.order_lines
                : [];
        },

        savedGiftCardChargeRequests: (state) => {
            return state.currentOrder !== false && 'gift_card_charge_requests' in state.currentOrder
                ? state.currentOrder.gift_card_charge_requests
                : [];
        },

        orderHasSingleItem: (state, getters) => {
            const lineLengthSum = getters.orderLines.length + getters.giftCardChargeRequests.length;
            return getters.orderLines
                && lineLengthSum === 1
                || (lineLengthSum === 1 && getters.orderLines.length === 1 && getters.orderLines[0].quantity === 1);
        },

        orderSplitDisabledInPOS: (state, getters) => {
            return getters.everythingDisabled
                || getters.orderHasExternalOrder
                || !getters.orderIsAllPrinted
                || getters.orderEmpty
                || !getters.paymentTypes.length
                || getters.orderHasSingleItem;
        },

        selectedCartItemIndex: (state) => {
            return state.selectedCartItemIndex;
        },

        selectedOrderLine: (state) => {
            if (state.selectedCartItemIndex === false) {
                return false;
            } else {
                return state.unsentOrderLines[state.selectedCartItemIndex];
            }
        },

        orderUuid: (state) => {
            return state.currentOrder.uuid;
        },

        orderEmpty: (state, getters) => {
            let savedOrderLines = getters.savedOrderLines;

            return state.currentOrder === false
                || ((savedOrderLines.length + state.unsentOrderLines.length === 0 && state.unsentGiftCardChargeRequests.length === 0) && getters.orderBalance === 0);
        },

        orderHasStatus: (state) => (status) => {
            return state.currentOrder && state.currentOrder.status === status;
        },

        orderReadyToCancel: (state) => {
            return state.currentOrder && state.currentOrder.status === 'open' && state.currentOrder.unpaid === state.currentOrder.total;
        },

        // Near-duplicate method in SplitOrder; make sure to update there if making changes here. 
        unsentOrderLinesTotal: (state) => {
            let total = 0;

            let orderLineTotal = (sum, orderLine) => {
                let price = orderLine.price ?? orderLine.sellable.price; // TODO 26 January

                let lineTotal = orderLine.quantity * price;

                if (orderLine.percentage_discount != null && orderLine.percentage_discount > 0) {
                    // Important that we round the discount amount before subtracting from the original total; if the
                    // reasoning escapes you, ask wise man Wel
                    lineTotal = lineTotal - Math.round(lineTotal * (orderLine.percentage_discount / 1000));
                }

                if (orderLine?.slaves?.length) {

                    lineTotal += orderLine.slaves.reduce(orderLineTotal, 0);
                }

                return sum + lineTotal;
            }

            total = state.unsentOrderLines.reduce(orderLineTotal, 0);

            return total;
        },

        unsentGiftCardChargeRequestsTotal: (state, getters) => {
            return state.unsentGiftCardChargeRequests.reduce((total, giftCardCharge) => total + giftCardCharge.amount, 0);
        },

        orderTotal: (state, getters) => {
            let total = (!getters.workingOnOpenOrder && state.currentOrder && !getters.secondContextIs('alsoMarkAsDelivered')) ? state.currentOrder.total : 0;
            return total + getters.unsentOrderLinesTotal;
        },

        orderTotalIncludingGiftCardChargeRequests: (state, getters) => {
            return getters.orderTotal - getters.unsentGiftCardChargeRequestsTotal;
        },

        orderBalance: (state, getters) => {
            return getters.unsentOrderLinesTotal - getters.unsentGiftCardChargeRequestsTotal - (getters?.order?.payments_total ?? 0);
        },

        orderLines: (state, getters) => {
            if (getters.workingOnOpenOrder) {
                return state.unsentOrderLines;
            } else if (getters.secondContextIs('alsoMarkAsDelivered')) { // The order is paid but should still appear visible
                return state.unsentOrderLines;
            } else {
                return getters.savedOrderLines.concat(state.unsentOrderLines);
            }
        },

        giftCardChargeRequests: (state, getters) => {
            if (getters.workingOnOpenOrder) {
                return state.unsentGiftCardChargeRequests;
            } else if (getters.secondContextIs('alsoMarkAsDelivered')) { // The order is paid but should still appear visible
                return state.unsentGiftCardChargeRequests;
            } else {
                return getters.savedGiftCardChargeRequests.concat(state.unsentGiftCardChargeRequests);
            }
        },

        preparedOrderLines: (state, getters) => {
            let orderLines = state.unsentOrderLines;
            let preparedOrderLines = [];

            const prepareOrderLine = (orderLine) => {
                let preparedOrderLine;

                if ('uuid' in orderLine) {
                    // Refund or re-save order line case

                    preparedOrderLine = {
                        uuid: orderLine.uuid,
                        quantity: parseInt(orderLine.quantity),
                        optouts: orderLine.optouts ?? null, // SELLABLETODO
                        //percentage_discount: orderLine.percentage_discount ?? null,
                    };
                } else {
                    const sellableType = orderLine.sellable_type;

                    // The API uses this different format for the sellable data because it greatly simplfied adapting
                    // the validation rules to the new sellable types. Whenever we aren't submitting order data (POST
                    // or PUT) to the backend, there will be an order line property 'sellable_type' containing the
                    // string type, and then a property 'sellable' holding the model data you'd expect. When submitting
                    // data, however we instead use the below format
                    preparedOrderLine = {
                        sellable_data: {
                            type: orderLine.sellable_type,
                            [sellableType]: {
                                uuid: orderLine.sellable.uuid,
                            },
                        }
                    };
                    
                    switch (sellableType) {
                        case 'product':
                            preparedOrderLine.product_modifier_group = orderLine.product_modifier_group ?? null;
                            preparedOrderLine.optouts = orderLine.optouts ?? null;
                            preparedOrderLine.sellable_data.product.price = orderLine.price ?? orderLine.sellable.price;

                            break;
                        case 'gift_card_type':
                            //  ?? orderLine.sellable.price
                            preparedOrderLine.sellable_data.gift_card_type.price = orderLine.price;

                            break;
                        case 'gift_card':
                            console.error("There shouldn't be gift cards here??");

                            break;
                        default:
                            console.error('Unknown sellable type!');
                    }

                    preparedOrderLine.quantity = parseInt(orderLine.quantity);
                }

                preparedOrderLine.comment = orderLine.comment ?? null;
                preparedOrderLine.receipt_text = orderLine.receipt_text ?? null;

                if (('percentage_discount' in orderLine)) {
                    // Percentage-discounted order line case

                    preparedOrderLine.percentage_discount = orderLine.percentage_discount;
                }

                if (orderLine?.slaves?.length) {
                    preparedOrderLine.slaves = [];
                    preparedOrderLine.slaves = orderLine.slaves.map(slave => prepareOrderLine(slave));
                }

                return preparedOrderLine;
            }

            for (const orderLine of orderLines) {
                preparedOrderLines.push(prepareOrderLine(orderLine));
            }

            return preparedOrderLines;
        },

        preparedGiftCardRequests: (state) => {
            return state.unsentGiftCardChargeRequests.map(chargeRequest => {
                let data = {
                    amount: chargeRequest.amount,
                };

                if (chargeRequest.uuid) {
                    data.uuid = chargeRequest.uuid;
                } else {
                    data.gift_card = {
                        uuid: chargeRequest.gift_card.uuid,
                    }
                }

                return data;
            });
        },

        indexOfUnsentOrderLineWithSameUuidAndPrice: (state) => (orderLine) => {
            function uuidPriceEq(o1, o2) {
                const getProductPrice = (o) => (('price' in o) ? o.price : o.sellable.price);

                return o1.sellable.uuid === o2.sellable.uuid && getProductPrice(o1) === getProductPrice(o2);
            }

            function productNameEq(lineOne, lineTwo) {
                // Okay, so we just decided (maybe we had already, and I forgot) that pre-save order lines (order lines
                // that became part of an open order when it was saved) should not be combined (in the frontend) with any
                // new additions. By not looking at each orderlines sellable.name (only product_name), we accomplish that. ^^
                
                return lineOne.product_name === lineTwo.product_name;
            }

            function orderLineEq(lineOne, lineTwo) {
                // Check price equality at current level
                const priceEqualityOfCurrentPair = uuidPriceEq(lineOne, lineTwo);
                const productNameEqualityOfCurrentPair = productNameEq(lineOne, lineTwo);
                //const neitherHasSlave = (lineOne.slave === undefined && lineTwo.slave === undefined);

                // Begin checking their slave(s)
                const lineOneSlaves = lineOne?.slaves?.length ?? 0;
                const lineTwoSlaves = lineTwo?.slaves?.length ?? 0;

                // Check if they have the same number of slaves
                let slavesEqual = lineOneSlaves === lineTwoSlaves;
                
                if (slavesEqual) {
                    // They have the same number of slaves; we begin recursively checking them pair by pair
                    for (let i = 0; i < Math.max(lineOne, lineTwo); i++) {
                        // If any one inequality is encountered, slavesEqual will be false
                        slavesEqual = slavesEqual && orderLineEq(lineOne.slaves[i], lineTwo.slaves[i])

                        if (!slavesEqual) {
                            // If slavesEqual doesn't evaluate to true, we break from the loop, slavesEqual remaining false
                            break;
                        }
                    }
                }
                
                return priceEqualityOfCurrentPair && productNameEqualityOfCurrentPair && slavesEqual;
            }

            let index = state.unsentOrderLines.findIndex(o => orderLineEq(o, orderLine));

            return index;
        },

        order: (state) => {
            return state.currentOrder;
        },

        workingOnOpenOrder: (state, getters) => {
            return getters.orderHasStatus('open') && getters.firstContextIs('sale') && getters.secondContextIsnt('considerLeftOrder');
        },

        percentageDiscount: (state) => {
            return state.currentOrder && state.percentageDiscount;
        },

        isRefundOrder: (state) => {
            return state.currentOrder && state.currentOrder.original_order;
        },

        discountsActive: (state) => {
            return [...new Set(state.unsentOrderLines.filter(o => o.sellable_type === 'product').map(o => o.percentage_discount != null ? o.percentage_discount : null ))];
        },

        orderHasTable: (state) => {
            return state.currentOrder && state.currentOrder.tables.length;
        },

        orderHasNoTable: (state) => {
            return state.currentOrder === false || state.currentOrder.tables.length === 0;
        },

        // Should return the single active table, if it exists, and otherwise an array of all tables the order has been assigned to
        // IMPTODO: Move logic to backend!!
        tables: (state, getters) => {
            if (state.currentOrder === false ) {
                return [];
            }

            if (state.currentOrder.tables.length === 0) {
                return [];
            }

            if (state.currentOrder.tables.length > 0) {
                let tables = [];
                if (getters.orderHasStatus('open')) {
                    let table = state.currentOrder.tables.find(table => table.pivot.active === 1);

                    if (table !== -1) {
                        tables.push(table);
                    }
                } else if (getters.orderHasStatus('completed') || getters.orderHasStatus('cancelled')) {
                    tables.push(state.currentOrder.tables[0]);
                }

                return tables;
            }
        },

        orderHasExternalOrder: (state) => {
            return state.currentOrder && state.currentOrder.external_order !== null;
        },

        orderIsAllPrinted: (state) => {
            return state.currentOrder && state.currentOrder.all_printed;
        },

        terminalText: (state) => {
            return state.terminalText;
        },
        QRUrl: (state) => {
            return state.QRUrl;
        },
        autoNavigateUrl: (state) => {
            return state.autoNavigateUrl;
        },        

        orderHasPayments: (state) => {
            return state.currentOrder.payments.length > 0;
        },

        orderHasCapturedPayments: (state) => {
            if (!state.currentOrder.payments || state.currentOrder.payments.length === 0) {
                return false;
            }

            for (const payment of state.currentOrder.payments) {
                if (payment.cancelled_at == null) {
                    return true; // At least one payment is not cancelled, so order shouldn't be reopened
                }
            }

            return false;
        },

        orderAssignedName: (state) => {
            return state.currentOrder.assigned_name;
        },
        
        getTableUuid: (state) => {
            return state.table_uuid;
        },
        
        getTableUuids: (state) => {
            return state.table_uuids;
        },

        getClientsNumber: (state) => {
            return state.clients_number;
        },

        returnMoneyShouldBeGiven: (state, getters) => {
            const order = state.currentOrder;

            if (!order) {
                return false;
            }

            if (!getters.contextIs(['sale', false])) {
                return false;
            }

            if (state.awaitingCancelAction) {
                return true;
            } else {
                // Return true if the order balance's sign is different from the total's
                return getters.orderBalance * getters.orderTotal < 0;
            }

        },

        hidePayOutToCustomerNotification: (state) => {
            return state.hidePayOutToCustomerNotification;
        },

        orderAwaitingCancelAction: (state, getters) => {
            return state.awaitingCancelAction;
        },
    },

    mutations: {
        resetOrder(state) {
            state.currentOrder = false;
            state.selectedOrderLineIndex = false;
            state.refundOrder = false;
            state.unsentOrderLines = [];
            state.percentageDiscount = false;
            state.terminalText = null;
            state.QRUrl = null;
            state.autoNavigateUrl = null;
            state.awaitingCancelAction = false;
        },
        
        setSelectedCartItemIndex (state, index) {
            state.selectedCartItemIndex = index;
        },

        addPendingOrderLine (state, orderLine) {
            state.unsentOrderLines.push(orderLine);

            if (state.currentOrder) {
                state.currentOrder.all_printed = false;
            }
        },

        addGiftCardChargeRequest (state, giftCardCharge) {
            state.unsentGiftCardChargeRequests.push(giftCardCharge);
        },

        updateGiftCardChargeRequestAmount (state, { index, amount }) {
            state.unsentGiftCardChargeRequests[index].amount = amount;
        },

        removeGiftCardChargeRequest (state, index) {
            state.unsentGiftCardChargeRequests.splice(index, 1);
        },

        changeOrderLineQtyAtIndex (state, data) {
            if (data.rootIndex != undefined) {
                state.unsentOrderLines[data.rootIndex].quantity += data.change;
    
                if (state.unsentOrderLines[data.rootIndex]?.slaves) {
                    state.unsentOrderLines[data.rootIndex].slaves.map(slaveLine => slaveLine.quantity += data.change);
                }
            } else if (data.indexes != undefined) {
                if (data.indexes.length === 1) {
                    state.unsentOrderLines[data.indexes[0]].quantity += data.change;
                } else if (data.indexes.length === 2) {
                    state.unsentOrderLines[data.indexes[0]].slaves[data.indexes[1]].quantity += data.change;
                }
            }

            if (state.currentOrder) {
                state.currentOrder.all_printed = false;
            }
        },

        setOrderLineQtyAtIndex (state, data) {
            if (data.rootIndex != undefined) {
                state.unsentOrderLines[data.rootIndex].quantity = data.newQty;
    
                if (state.unsentOrderLines[data.rootIndex]?.slaves) {
                    state.unsentOrderLines[data.rootIndex].slaves.map(slaveLine => slaveLine.quantity = data.newQty);
                }
            } else if (data.indexes != undefined) {
                // IMPTODO: Don't know what this code is supposed to be, or that this case should exist; agree with Wel first
                if (data.indexes.length === 1) {
                    state.unsentOrderLines[data.indexes[0]].quantity = data.newQty;
                } else if (data.indexes.length === 2) {
                    state.unsentOrderLines[data.indexes[0]].slaves[data.indexes[1]].quantity = data.newQty;
                }
            }

            if (state.currentOrder) {
                state.currentOrder.all_printed = false;
            }
        },

        replaceOrderLineAtIndex(state, data) {
            Vue.set(state.unsentOrderLines, data.index, data.orderLine);

            // Ugh... to ensure correct order total and balance after updating an order line with modifiers
            state.unsentOrderLines.push(1);
            state.unsentOrderLines.pop();
        },

        removeOrderLineAtIndex(state, index) {
            state.unsentOrderLines.splice(index, 1);

            if (state.currentOrder) {
                state.currentOrder.all_printed = false;
            }
        },

        clearUnsent(state) {
            state.unsentOrderLines = [];
            state.selectedCartItemIndex = false;
            state.unsentGiftCardChargeRequests = [];

        },

        // TODO: Consdering retiring the below two, because I added their code to the above
        clearUnsentOrderLines(state) {
            state.unsentOrderLines = [];
            state.selectedCartItemIndex = false;
        },

        clearUnsentGiftCardChargeRequests(state) {
            state.unsentGiftCardChargeRequests = [];
        },

        setOrderLines(state, orderLines) {
            state.unsentOrderLines = orderLines;
        },

        setCurrentOrder(state, order) {
            state.currentOrder = order;
        },

        setCurrentOrderStatus(state, status) {
            let order = state.currentOrder;
            order['status'] = status;
            state.currentOrder = order;
        },

        setCurrentAsRefundOrder(state) {
            state.refundOrder = state.currentOrder;
        },

        setRefundOrder(state, value) {
            state.refundOrder = value;
        },

        copySavedOrderLinesAndChargeRequests(state) {
            state.unsentOrderLines = state.currentOrder.order_lines;
            state.unsentGiftCardChargeRequests = state.currentOrder.gift_card_charge_requests;
        },

        setOrderPercentageDiscount(state, value) {
            if (state.currentOrder !== false) {
                state.percentageDiscount = value;

                let valueToSend = value === false ? null : value;

                const setDiscount = (o) => {
                    Vue.set(o, 'percentage_discount', valueToSend);

                    o.slaves.forEach((s) => {
                        Vue.set(s, 'percentage_discount', valueToSend);
                    });
                }

                state.unsentOrderLines.filter(o => o.sellable_type === 'product').forEach(setDiscount);
                state.currentOrder.all_printed = false;
            }
        },

        setOrderLinePercentageDiscount(state, value) {
            Vue.set(state.unsentOrderLines[state.selectedCartItemIndex], 'percentage_discount', value);

            state.unsentOrderLines[state.selectedCartItemIndex].slaves.forEach((s) => {
                if (s.product_modifier_group) {
                    Vue.set(s, 'percentage_discount', value);
                }
            });

            if (state.unsentOrderLines.length === 1) {
                state.percentageDiscount = value ?? false;
            }

            if (state.currentOrder) {
                state.currentOrder.all_printed = false;
            }
        },

        // The above function will actually apply or remove a percentage discount from an order's order lines. The below
        // function will be called when order lines are added to a discount order; since new order lines should not have
        // the previous order lines' discount, we should mark it in the state that the percentage discount is not active,
        // in order that the discount (or another discount) can be (re-)applied to all order lines.
        setPercentageDiscountIndicator(state, value) {
            state.percentageDiscount = value;
        },

        setOrderLineComment(state, comment) {
            Vue.set(state.unsentOrderLines[state.selectedCartItemIndex], 'comment', comment);
        },

        setOrderLineReceiptText(state, receiptText) {
            Vue.set(state.unsentOrderLines[state.selectedCartItemIndex], 'receipt_text', receiptText);
        },

        setTableUuid(state, uuid) {
            state.table_uuid = uuid;
        },

        setTableUuids(state, uuids) {
            state.table_uuids = uuids;
        },

        setClientsNumber(state, number) {
            state.clients_number = number;
        },

        setTerminalText(state, text) {
            state.terminalText = text;
        },
        setQRUrl(state, url) {
            state.QRUrl = url;
        },
        setAutoNavigateUrl(state, url) {
            state.autoNavigateUrl = url;
        },          

        setOrderAssignedName(state, name) {
            if (state.currentOrder) {
                state.currentOrder.assigned_name = name;
            }
        },

        // Uhm, move this when finished
        precautionarySaveOrderLinesLengthToLocalStorage(state)
        {
            window.localStorage.setItem('orderLines', state.unsentOrderLines.length);
        },

        setAwaitingCancelAction(state, value) {
            state.awaitingCancelAction = value;
        },

        setHidePayOutToCustomerNotification(state, value) {
            state.hidePayOutToCustomerNotification = value;
        },
    },

    actions: {
        updateOrder({ state, commit, getters }, payload) {
            commit('setAwaitingUpdateOrderResponse', true);

            let params = {
                register_uuid: getters.register.uuid,
                cashier_uuid: getters.cashier.uuid,
                order_lines: getters.preparedOrderLines,
                gift_card_charge_requests: getters.preparedGiftCardRequests,
                test_mode: getters.testMode,
                suspend: true,
            };

            // We use destructuring assignment in order to avoid having to provide extraParams every time
            const { extraParams = {} } = payload ?? {};

            // Probably pass this along with extraParams?
            if (getters.order.assigned_name) {
                params.assigned_name = getters.order.assigned_name;
            }

            if (extraParams) {
                params = { ...params, ...extraParams };
            }

            // TODO: Handle error i.e. by creating a notif? Or let calling methods do so?
            return axios.put('orders/' + state.currentOrder.uuid, params)
                .then(response => {
                    const order = response.data.order;

                    commit('setCurrentOrder', order);

                    return response;
                }).finally(() => {
                    commit('setAwaitingUpdateOrderResponse', false);
                });
        },

        replacementProductionPrint({ commit, getters }, { uuid }) {
            const data = {
                register_uuid: getters.register.uuid,
                cashier_uuid: getters.cashier.uuid,
                test_mode: getters.testMode,
            }

            return axios.post('orders/' + uuid + '/replacement-production-print', data);
        },

        retainOrder({ commit, getters }, { uuid }) {
            const params = {
                register_uuid: getters.register.uuid,
                cashier_uuid: getters.cashier.uuid,
                test_mode: getters.testMode,
            }

            return axios.put('orders/' + uuid + '/retain', params);
        },
    }
}