/**
 * 
 * @param {cartItem[]} cartItems
 * @returns {CartPackage[]} 
 */
export default function reCalculatePackesList(cartItems, packagingOptout, selectedDeliveryType) {
    // Go through all items and check if they need packaging
    let packages = [];
    let packagesAmount = {};

    for (const item of cartItems) {
        // console.log('cart item=', item)
        if (item.product.packages === undefined || item.product.packages === null) {
            continue;
        }
        for (const pkg of item.product.packages) {
            let packageAmount = item.amount * pkg.volume;
            let itemPrice = pkg.price * packageAmount;

            if (itemPrice <= 0 || pkg.is_soldout > 0) {
                continue;
            }

            if (packagesAmount[pkg.id] === undefined) {
                try {
                    packagesAmount[pkg.id] = {
                        id: parseInt(pkg.id),
                        amount: 1,
                        volume: parseFloat(parseInt(item.quantity) * pkg.volume),
                        price: parseFloat(pkg.price),
                        name: pkg.name,
                        order_types: JSON.parse(pkg.order_types),
                        delivery_types: JSON.parse(pkg.delivery_types),
                        required: parseInt(pkg.required) == 1 ? true : false,
                        i_want_text: pkg.i_want_text ? pkg.i_want_text : 'yes_please',
                        i_dont_want_text: pkg.i_dont_want_text ? pkg.i_dont_want_text : 'optional',
                    };
                } catch (error) {}
            } else {
                packagesAmount[pkg.id].volume +=  parseInt(item.quantity) * pkg.volume;
            }
        }
    }


    // Merge packages
    for (let index in packagesAmount) {
        let pkg = packagesAmount[index];

        // Check if delivery types includes the specific delivery
        if (pkg.delivery_types && !pkg.delivery_types.includes(selectedDeliveryType)) {
            continue;
        }

        // Check if we have the correct order type
        const orderType = 'pos' // TODO: assuming order type is post
        if (pkg.order_types && !pkg.order_types.includes(orderType)) {
            continue;
        }
        let packageAmount = Math.ceil(pkg.amount * pkg.volume);
        let itemPrice = pkg.price * packageAmount;
        let itemData = {
            id: pkg.id,
            name: pkg.name,
            required: pkg.required,
            i_want_text: pkg.i_want_text,
            i_dont_want_text: pkg.i_dont_want_text,
        };
        // console.log("Put in package: ",{ packageAmount, itemPrice, itemData })
        packages.push({ packageAmount, itemPrice, itemData });
    }

    // console.log('packages=', packages)

    // Filter out all packages from cart
    let outputPackages = []

    // And add them again...
    for (const pkg of packages) {
        let { packageAmount, itemPrice, itemData } = pkg;
        let packagePrice = packagingOptout.includes(itemData.id) ? 0 : itemPrice;
        
        let newPkg = {
            amount: packageAmount,
            price: itemData.required ? parseFloat(itemPrice) : packagePrice,
            ...itemData
        }

        outputPackages.push(newPkg)
    }
    return outputPackages
}

